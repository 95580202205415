<template>
  <div class="box">
    <!-- 轮播图 -->
    <div class="box_lunbo" v-if="list1.length > 0">
      <el-carousel height="5.3rem">
        <el-carousel-item v-for="(item, i) in list1" :key="i">
          <div
            :class="{
              lunbo_item: true,
              cursor_pointer: item.contentDetails || item.contentUrl,
            }"
            @click="toDetailOne(item.id, item.contentDetails, item.contentUrl)"
          >
            <img :src="item.contentImgUrl || $defaultImg" alt="" />
            <!-- <div class="lunbo_item_info">
              <div class="lunbo_item_info_title">{{ item.contentTitle }}</div>
              <div class="lunbo_item_info_value">
                {{ item.contentDescription }}
              </div>
            </div> -->
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- ------------------------------------------- -->

    <!-- 热门赛事 -->
    <div class="match" v-if="list2.length > 0">
      <div class="moment_title">
        <div class="moment_title_ch">热门赛事</div>
        <div class="moment_title_en">Popular Events</div>
      </div>
      <div class="match_list">
        <div class="match_item_box" v-for="(item, i) in list2" :key="i">
          <div class="match_item">
            <img :src="item.contentImgUrl || $defaultImg" alt="" />
            <div class="match_bot">
              <div class="match_title">
                {{ item.contentTitle }}
              </div>
              <div class="match_type">
                <!-- contentKeyword设定是否为线上赛 -->
                <img
                  v-if="item.contentKeyword == '1'"
                  src="@/assets/image/page/matchOnLine.png"
                  class="match_type_img"
                />
                <img
                  v-else
                  src="@/assets/image/page/matchOffLine.png"
                  class="match_type_img"
                />
              </div>
              <!-- 
                报名时间：文章作者
                比赛时间：文章来源
               -->
              <div class="match_time">报名时间：{{ item.contentAuthor }}</div>
              <div class="match_time">比赛时间：{{ item.contentSource }}</div>
            </div>
            <div class="match_mask">
              <div class="match_mask_big">
                <div style="margin-bottom: 20px">微信扫描小程序码 报名参赛</div>
                <div class="match_mask_box">
                  <img
                    src="@/assets/image/page/toPhone.png"
                    class="match_mask_box_img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 精彩瞬间 -->
    <div class="moment" v-if="list3.length > 0">
      <div class="moment_title">
        <div class="moment_title_ch">精彩瞬间</div>
        <div class="moment_title_en">Moment</div>
      </div>
      <div class="moment_list">
        <div class="moment_item_box" v-for="(item, i) in list3" :key="i">
          <div class="moment_item">
            <div class="moment_img_box">
              <img
                :src="item.contentImgUrl || $defaultImg"
                @click="toDetail(item.id)"
              />
              <div class="moment_img_box_box">
                <img src="@/assets/image/page/momentImg.png" />
              </div>
            </div>
            <div class="moment_item_title">
              <div>
                {{ item.contentTitle.slice(0, 30) }}
                {{ item.contentTitle.length > 30 ? "..." : "" }}
              </div>
              <!-- <div
                class="moment_item_time_one"
                v-if="item.contentTitle.length > 30"
              >
                {{ item.contentDatetime.split(" ")[0] }}
              </div> -->
              <div class="moment_item_time_two">
                {{ item.contentDatetime | timeFormat }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- --------------------------------------- -->

    <!-- 新闻动态 -->
    <div class="news_box" v-if="news1.id && news2.length > 0">
      <div class="news_title">新闻动态</div>
      <div class="news_list">
        <div class="news_left" @click="toDetail(news1.id)">
          <img
            :src="news1.contentImgUrl || $defaultImg"
            class="news_left_img"
          />
          <div class="news_left_title">{{ news1.contentTitle }}</div>
        </div>
        <div class="news_right">
          <div
            class="news_right_item"
            v-for="(item, i) in news2"
            :key="i"
            @click="toDetail(item.id)"
          >
            <img
              :src="item.contentImgUrl || $defaultImg"
              class="news_right_item_img"
            />
            <div class="news_right_item_info">
              <div class="news_right_item_info_title">
                {{ item.contentTitle }}
              </div>
              <div class="news_right_item_info_time">
                {{ item.contentDatetime | timeFormat }}
              </div>
              <div class="news_right_item_info_value">
                {{ item.contentDescription }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- --------------------------------------------- -->

    <!--  官方公告-->
    <div class="notice_box" v-if="list4.length > 0">
      <div class="news_title">官方公告</div>
      <div class="notice_list">
        <div
          class="notice_item"
          v-for="(item, i) in list4"
          :key="i"
          @click="toDetail(item.id)"
        >
          <div class="notice_item_circor"></div>
          <div class="notice_item_title">
            {{ item.contentTitle }}
          </div>
          <div class="notice_item_time">{{ item.contentDatetime | timeFormat }}</div>
        </div>
      </div>
    </div>
    <!-- ------------------------------------------------ -->

    <!-- 合作伙伴 -->
    <div class="partner">
      <div class="partner_left"></div>
      <div class="logo_box">
        <img
          class="logo_box_img"
          src="@/assets/image/page/footerLogo.png"
          alt=""
        />
        <img
          class="logo_box_font"
          src="@/assets/image/page/footerFont.png"
          alt=""
        />
      </div>
      <div class="partner_right">
        <div class="partner_right_title">合作伙伴</div>
        <div class="partner_list" v-if="list5.length > 0">
          <div class="partner_item" v-for="(item, i) in list5" :key="i">
            <img
              class="partner_item_img"
              :src="item.contentImgUrl || $defaultImg"
              alt=""
            />
            <div>{{ item.contentTitle }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- ------------------------------------------------------------------- -->
  </div>
</template>

<script>
import { getList } from "@/api/login";
export default {
  name: "homePage",
  data() {
    return {
      list1: [],
      list2: [],
      list3: [],
      news1: [],
      news2: [],
      list4: [],
      list5: [],
    };
  },
  created() {
    this.getList1();
    this.getList2();
    this.getNews();
    this.getList3();
    this.getList4();
    this.getList5();
  },
  methods: {
    async getList1() {
      try {
        let res = await getList({
          categoryCode: "home-banner",
          pageSize: 10,
          pageNum: 1,
        });
        if (res.code == "0") {
          this.list1 = res.data.banner || [];
        } else {
          this.list1 = [];
        }
      } catch (error) {}
    },
    async getList2() {
      try {
        let res = await getList({
          categoryCode: "home-match",
          pageSize: 10,
          pageNum: 1,
        });
        if (res.code == "0") {
          this.list2 = res.data.cmsContents.dataList || [];
        } else {
          this.list2 = [];
        }
      } catch (error) {}
    },
    async getList3() {
      try {
        let res = await getList({
          categoryCode: "home-moment",
          pageSize: 10,
          pageNum: 1,
        });
        if (res.code == "0") {
          this.list3 = res.data.cmsContents.dataList || [];
        } else {
          this.list3 = [];
        }
      } catch (error) {}
    },
    // 新闻动态
    async getNews() {
      try {
        let res = await getList({
          categoryCode: "home-news",
          pageSize: 5,
          pageNum: 1,
        });
        if (res.code == "0") {
          let arr = res.data.cmsContents.dataList || [];
          this.news1 = arr[0] || [];
          this.news2 = arr.slice(1, 4) || [];
        } else {
          this.news1 = [];
          this.news2 = [];
        }
      } catch (error) {}
    },
    async getList4() {
      try {
        let res = await getList({
          categoryCode: "home-notice",
          pageSize: 10,
          pageNum: 1,
        });
        if (res.code == "0") {
          this.list4 = res.data.cmsContents.dataList || [];
        } else {
          this.list4 = [];
        }
      } catch (error) {}
    },
    async getList5() {
      try {
        let res = await getList({
          categoryCode: "home-partner",
          pageSize: 8,
          pageNum: 1,
        });
        if (res.code == "0") {
          this.list5 = res.data.cmsContents.dataList || [];
        } else {
          this.list5 = [];
        }
      } catch (error) {}
    },
    toDetailOne(id, detail, url) {
      if (url) {
        let reg = /(http|https):\/\/([\w.]+\/?)\S*/;
        if (reg.test(url)) {
          window.open(url, "_blank");
        } else {
          this.$router.push(url);
        }
      }
      if (!detail) {
        return false;
      }
      sessionStorage.removeItem("admin_high_light_two");
      this.$router.push({
        path: "/detail",
        query: {
          id,
        },
      });
    },
    toDetail(id) {
      sessionStorage.removeItem("admin_high_light_two");
      this.$router.push({
        path: "/detail",
        query: {
          id,
        },
      });
    },
  },
};
</script>

<style scoped>
.box_lunbo {
  margin-bottom: 0.6rem;
}
.lunbo_item {
  height: 100%;
  width: 100%;
  position: relative;
}
.cursor_pointer {
  cursor: pointer;
}
.lunbo_item img {
  width: 100%;
  height: 100%;
  z-index: 0;
}
.lunbo_item_info {
  position: absolute;
  width: 8.56rem;
  height: 1.1rem;
  background: url(../../assets/image/page/lunboBg.png) no-repeat;
  background-size: 100% 100%;
  z-index: 22;
  top: 3rem;
  left: 1.15rem;
  color: #fff;
  padding: 0.2rem;
}
.lunbo_item_info_title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0.1rem;
}
.lunbo_item_info_value {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

/* 热门赛事 */
.match {
  margin-bottom: 1.2rem;
}
.match_list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.7rem;
}
.match_item_box {
  width: 33.3%;
  padding-right: 20px;
  margin-bottom: 20px;
}
.match_item {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.match_mask {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(6, 6, 6, 0.5);
}
.match_item:hover .match_mask {
  display: block;
}
.match_mask_big {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 11;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 1.5px;
}
.match_mask_box {
  height: 1.7rem;
  width: 1.7rem;
  border-radius: 0.85rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.match_mask_box .match_mask_box_img {
  width: 1.56rem;
  height: 1.56rem;
}
/* .match_item:nth-child(3n) {
  margin-right: 0;
} */
.match_item img {
  width: 100%;
  height: 2.45rem;
}
.match_bot {
  width: 100%;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 10px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
}
.match_title {
  color: #3e3a39;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  width: 3rem;
  overflow: hidden; /*内容超出后隐藏*/
  text-overflow: ellipsis; /* 超出内容显示为省略号 */
  white-space: nowrap; /* 文本不进行换行 */
}
.match_type {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.match_type .match_type_img {
  width: 0.6rem;
  height: 0.16rem;
}
.match_time {
  color: #8a8a8a;
  font-size: 12px;
  margin-bottom: 10px;
}

/* 精彩瞬间 */
.moment {
  margin-bottom: 1.2rem;
}
.moment_title {
  text-align: center;
  margin-bottom: 0.2rem;
}
.moment_title_ch {
  font-size: 32px;
  color: #333;
  font-weight: 700;
}
.moment_title_en {
  margin-top: -10px;
  width: 100%;
  font-size: 28px;
  color: #cccccc;
}
.moment_list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.7rem;
}
.moment_item_box {
  width: 33.3%;
  margin-bottom: 20px;
  padding-right: 20px;
}
.moment_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.moment_img_box {
  position: relative;
  width: 100%;
  height: 2.44rem;
}
.moment_img_box img {
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.moment_img_box_box {
  position: absolute;
  bottom: 0;
  left: 20px;
  width: 0.24rem;
  height: 0.24rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.moment_img_box_box img {
  height: 0.17rem;
  width: 0.17rem;
}
.moment_item_title {
  position: relative;
  padding: 10px 15px;
  width: 100%;
  color: #3e3a39;
  font-size: 16px;
  background-color: #fff;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
  letter-spacing: 1.5px;
  font-weight: 700;
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.moment_item_time_one {
  position: absolute;
  right: 15px;
  bottom: 10px;
  color: #3e3a39;
  font-size: 12px;
  font-weight: 500;
}
.moment_item_time_two {
  color: #3e3a39;
  font-size: 12px;
  font-weight: 500;
  width: 115px;
  flex-shrink: 0;
  text-align: right;
}

/* 新闻动态 */
.news_box {
  padding: 0 0.7rem;
  margin-bottom: 1.2rem;
}
.news_title {
  font-size: 30px;
  color: #3e3a39;
  font-weight: 700;
  padding-bottom: 0.1rem;
  border-bottom: 6px solid #d80c24;
  width: 1.56rem;
  margin-bottom: 0.16rem;
}
.news_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.news_left {
  width: 6.6rem;
  height: 3.71rem;
  position: relative;
}
.news_left_img {
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.news_left_title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.59rem;
  background-color: rgba(62, 58, 57, 0.3);
  color: #fff;
  font-size: 16px;
  line-height: 0.59rem;
  padding-left: 0.2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.news_right {
  width: 4.6rem;
  height: 3.71rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.news_right_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.news_right_item_img {
  width: 2.08rem;
  height: 1.17rem;
}
.news_right_item_info {
  flex: 1;
  height: 1.17rem;
  padding: 0 0 0.2rem 0.1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #b5b5b6;
}
.news_right_item_info_title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 16px;
  color: #3e3a39;
}
.news_right_item_info_value {
  width: 2.42rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notice_box {
  padding: 0 0.7rem;
  margin-bottom: 1.2rem;
}
.notice_list {
  padding: 0.2rem 0.4rem;
  background-color: #f5f5f4;
  border-radius: 4px;
}
.notice_item {
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
  cursor: pointer;
}
.notice_item:last-child {
  margin-bottom: 0;
}
.notice_item_circor {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  margin-right: 12px;
  background-color: black;
}
.notice_item_title {
  width: 9rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notice_item_time {
  flex: 1;
  text-align: right;
  color: #3e3a39;
}

.partner {
  width: 100%;
  height: 4.86rem;
  background: url(../../assets/image/page/bottomBg.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  overflow: hidden;
  margin-bottom: -1px;
  position: relative;
}
.partner_left {
  width: 4rem;
  height: 4.86rem;
  background: linear-gradient(15deg, #f08602 0%, #e60012 100%);
  transform: skew(-20deg) translateX(-1rem);
  margin-right: 20px;
}
.logo_box {
  position: absolute;
  top: 0.94rem;
  left: 0.7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo_box_img {
  width: 1.66rem;
  height: 1.36rem;
  margin-bottom: 0.28rem;
}
.logo_box_font {
  width: 1.94rem;
  height: 0.88rem;
}
.partner_right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.partner_right_title {
  width: 1.47rem;
  font-size: 30px;
  color: #d50e23;
  padding-bottom: 12px;
  border-bottom: 6px solid #d50e23;
  margin-bottom: 0.2rem;
}
.partner_list {
  display: flex;
  flex-wrap: wrap;
}
.partner_item {
  width: 1.88rem;
  height: 1.32rem;
  margin-right: 0.13rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 0.13rem;
  color: #3e3a39;
  font-size: 16px;
}
.partner_item:nth-child(4n) {
  margin-right: 0;
}
.partner_item_img {
  height: 0.7rem;
  width: 1.3rem;
  margin-bottom: 0.15rem;
}
</style>
